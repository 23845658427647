import React from 'react';

const Shop = () => {
    return (
        <div>

            <p className='text-5xl mt-[100px] text-rose-400 text-center'> This page is undergoing upgrades and will be back soon
                <br /> Please visit us later.</p>
        </div>
    );
};

export default Shop;