import React from 'react';

const Marketplace = () => {
    return (
        <div className='text-5xl mx-auto text-rose-400 text-center'>
            This is shop route
        </div>
    );
};

export default Marketplace;