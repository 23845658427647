import React from 'react';

const Cart = () => {
    return (
        <div>
            <div>
                <p className=' text-center text-5xl pt-20    font-bold  text-red-500'>currently this page is under development, please visite us later </p>
            </div>
        </div>
    );
};

export default Cart;