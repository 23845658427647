import React from 'react';

const CoreManagedServices = () => {
    return (
        <div className="lg:w-3/4 md:w-[70%] sm:w-full mx-auto">
            <p className="font-merriweather mx-auto text-justify  mb-[91px] sm:px-5 mt-10">
                Our Core Managed Services include:
                <br />
                <br />
                <span className='font-black'>Infrastructure Management:</span> This service includes the day-to-day management and maintenance of your infrastructure including monitoring, patching, and backups. This ensures that your infrastructure is always running smoothly and securely.
                <br />
                <br />
                <span className='font-black'>Security Management:</span> Our security management services include threat detection and response, firewall management, and compliance management. We keep your data and systems secure by proactively identifying and mitigating threats.
                <br />
                <br />
                <span className='font-black'>Backup and Disaster Recovery:</span> Our Backup and Disaster Recovery services ensure that your data is always protected, and in case of a disaster, we have a plan in place to quickly and efficiently restore your systems.
                <br />
                <br />
                <span className='font-black'>Network Management:</span> Our <a href="/solutions/ZeroTrustNetworking" className="text-blue-500"> Network Management </a> services include monitoring, troubleshooting and management of your network infrastructure. This includes routers, switches, and WAN connections, ensuring that your network is always available and performing optimally.
                <br />
                <br />
                Cloud Management: Our <a href="/cloud-management" className="text-blue-500"> Cloud Management</a> services include the planning, design, and deployment of cloud-based solutions. This includes cost optimization, <a href="/cloud-security" className="text-blue-500"> security management</a>, and ongoing maintenance of your cloud environments.
                <br />
                <br />
                Our team of experts work closely with you to understand your specific needs and tailor our services to meet those needs. With our <a href="/solutions" className="text-blue-500">Core Managed Services </a>, you can rest easy knowing that your infrastructure is being managed by certified professionals. This allows you to focus on your core business, while we take care of the day-to-day management of your IT environment. In addition to our 24/7/365 service desk and white glove service delivery, we provide cost optimization and continuous improvement services – so you can focus on running your business, rather than keeping the lights on. <a href="/lets-talk" className="text-blue-500">Contact us</a> today to learn more about how our Core Managed Services can benefit your business.
            </p>
        </div>
    );
};

export default CoreManagedServices;