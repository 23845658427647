import React from 'react';

const Error = () => {
    return (
        <div>
            <p className=' text-center text-5xl pt-20    font-bold  text-red-500'>Page not found</p>
        </div>
    );
};

export default Error;